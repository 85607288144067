import React from 'react';
import contactSectionArrow from './contactSectionArrow.svg';
import contactSectionBgrnd from './contactSectionBgrnd.png';

function ContactSection() {
  return (
    <div className="contact-section-container">
      {/* DOESN'T WORK ON MOBILE */}
      <div className="section">
        <div className="contact-section-inner my-5 position-relative">
          <img
            src={contactSectionBgrnd}
            alt="contact section background"
            width="100%"
          />
          <div className="contact-section-overlay p-5">
            <div className="contact-section-overlay-arrow">
              <img
                src={contactSectionArrow}
                alt="contact section arrow"
                width="100%"
              />
            </div>
            <div className="row px-5 py-0">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <h4>Hit us up and we&apos;ll get back to you in no time.</h4>
                <h6>
                  Like what you see?
                  <br />
                  Have a project idea?
                  <br />
                  Don&apos;t know if you should apply?
                  <br />
                  Just want to say hi?
                </h6>
                <button className="intro-apply">
                  <a href="mailto:medlaunch-info@umich.edu">Let&apos;s Talk</a>
                </button>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 offset-md-2">
                <h6>
                  Follow us so you’ll never miss our latest projects and events!
                </h6>
                <div className="social-icons">
                  <a
                    href="https://www.facebook.com/medlaunchmichigan/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook-official"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/medlaunch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.medium.com/medlaunch"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-medium"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/medlaunch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
