import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.css';
import navLogo from './navLogo.png';

function Nav() {
  const [dropdownActive, setDropdownActive] = useState(false);

  return (
    <div className="header">
      <nav className="top-navbar navbar navbar-expand-lg navbar-light bg-none px-5">
        <div className="navbar-brand">
          <NavLink
            to="/"
            className="nav-header-logo"
            onClick={() => setDropdownActive(false)}
          >
            <img src={navLogo} width="80px" height="60px" alt="Logo" />
          </NavLink>
        </div>
        <button
          className="navbar-toggler"
          style={{ color: 'white' }}
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <div className="navbar-nav ml-auto d-flex align-items-center">
            <div className="nav-item dropdown">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="nav-link px-0 dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={dropdownActive ? { color: 'red' } : {}}
              >
                ABOUT
              </a>
              <div
                className="dropdown-menu"
                style={{ margin: '0 0 0 1vw' }}
                aria-labelledby="navbarDropdown"
                onClick={() => setDropdownActive(true)}
                onKeyDown={() => setDropdownActive(true)}
                role="button"
                tabIndex="0"
              >
                <NavLink to="/team" className="nav-item nav-link">
                  <span>Our Team</span>
                </NavLink>
                <NavLink to="/mission" className="nav-item nav-link">
                  <span>Our Mission</span>
                </NavLink>
                <NavLink to="/origins" className="nav-item nav-link">
                  <span>Our Story</span>
                </NavLink>
                <NavLink to="/founding" className="nav-item nav-link">
                  <span>Our Origin</span>
                </NavLink>
              </div>
            </div>
            <NavLink
              to="/biodesign-challenge"
              className="nav-item nav-link px-0"
              onClick={() => setDropdownActive(false)}
            >
              <span>BIODESIGN CHALLENGE</span>
            </NavLink>
            <NavLink
              to="/projects"
              className="nav-item nav-link px-0"
              onClick={() => setDropdownActive(false)}
            >
              <span>PROJECTS</span>
            </NavLink>
            <NavLink
              to="/faq"
              className="nav-item nav-link px-0"
              onClick={() => setDropdownActive(false)}
            >
              <span>FAQ</span>
            </NavLink>
            <NavLink
              to="/contact"
            >
              <button
                className="apply apply-item apply-link px-0"
                onClick={() => setDropdownActive(false)}
              >
                <span>GET INVOLVED</span>
              </button>
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
