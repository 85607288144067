import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    const elt = document.querySelector('#root');
    elt && elt.scrollIntoView();
  }, [pathname]);

  return null;
}
