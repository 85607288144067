import React from 'react';
import Header from '../templates/Header';
import './OurStory.css';
import aboutHeaderIcon from './aboutHeaderIcon.svg';
import Space from './aboutImgs/space.png';

function DavidsStory() {
  return (
    <div>
      <div className="our-story-header-container">
        <Header
          title="The Founding of MedLaunch"
          img={Space}
          imgSvg={aboutHeaderIcon}
        />
      </div>
      <div className="section">
        <h4>
          When we started MedLaunch, we could have never imagined the wonderful
          journey it would take us on.
        </h4>
        <div className="author">By David Chang</div>
      </div>
      <div className="story-container">
        <div className="section">
          <p>
            It began with a hackathon. Or maybe it began on a Friday evening
            after one of those long biomedical engineering labs, when I asked
            Mikey, who was clearly the smartest student in the class, if he
            wanted to join me in starting our very own health hackathon. Or
            perhaps it was the many weekends we spent attending entrepreneurship
            workshops, the projects I worked on with friends, or the various
            contentions we had with faculty and students along the way as we
            scrambled to formulate just exactly what it was we wanted to create.
            Maybe it was the one interest meeting we had in a dingy classroom in
            Mason Hall, months before we started doing anything real. Only a few
            students showed up, but nonetheless, with all my might I pitched my
            idea for a new kind of community for biomedical innovation.
            Unbeknownst to us, a few of those silent attendees would later
            become some of the first devotees of MedLaunch.
          </p>
          <p>
            So many changes occurred in those early days that it’s difficult to
            say when MedLaunch, that is the MedLaunch you see today, truly
            began.
          </p>
          <p>
            In a way, it began with all these things. And yet, it seemed to have
            begun long before that. It began with a hunger that started before
            any one of us set foot on campus. An all too present awareness of
            how short our time in college would be, and an aching desire to do
            something in the time we would have.
          </p>
          <p>
            As I finished my sophomore year of college, I still hadn’t built
            something that I could call my own. I only had two years left to do
            something, and I wanted to do something big. It didn’t feel like
            enough time. Surely, I would fail, or else ruin my prospects of
            success elsewhere. But I knew that if I didn’t try, I would regret
            it dearly.
          </p>
          <p>
            That summer, I attended a health hackathon and I met Sujai, who was
            one year younger than me, but who I quickly realized shared the same
            fire and desire to create something. Little did I know how large a
            role he would play in MedLaunch’s creation and its survival after I
            would be gone. After attending that hackathon, we wanted to create
            our own health hackathon, but what started as a hackathon soon
            evolved into something much greater. We wanted to create a community
            for people passionate about biomedical innovation and design. I
            started by enlisting my friends from biomedical engineering, Mikey,
            Ashish and Saswat, all of whom were passionate about biomedical
            innovation and looking for something to channel their enthusiasm
            towards. I also recruited my friend Roseanne, an art student, who I
            knew could make us some cool graphics that would inspire and show
            others what it was we stood for.
          </p>
          <p>
            The problem was this kind of community already existed, at least on
            paper. In the beginning, it was all about how we could be different.
            With over a thousand student organizations and various programs at
            the University of Michigan, almost every person we encountered asked
            us how we were different from the things that already existed. For a
            while, we struggled with this question. We eventually came up with
            the idea of the Biodesign Challenge, an annual year-long competition
            where students work in small teams to solve a pressing healthcare
            problem.
          </p>
          <p>
            While the Biodesign Challenge wasn’t a drastic departure from things
            that already existed at the university, it was different enough for
            people to stop asking the question of uniqueness. The truth is that
            whether we were truly different or not was irrelevant to us. We had
            grown discontent with the other opportunities, the inefficiencies
            and general lack of inspiration. We felt that there were so many
            more problems and technologies that students could be working on in
            the biomedical space that were currently not being addressed at the
            university. We thought that the other things people were working on
            were boring and lacking an edge. What we cared about was being
            better than the alternatives. We wanted to create a place where you
            could achieve your full creative potential, where young artists and
            engineers could join forces to design beautiful technologies. What
            we longed for more than anything was a sense of belonging and a
            chance to connect with others over the shared experience of building
            something greater than ourselves.
          </p>
          <p>
            Above all, we simply wanted to create something that we could call
            our own. To be able to point and say, <i>we made this</i>.
          </p>
          <p>
            With such lofty goals and expectations, when it came to actually
            getting started, we froze up. Rather than start building right away,
            we kept ideating, refining our mission statement, and pitching our
            Biodesign Challenge to organizations that could potentially give us
            money. I wanted us to work on something big, like cancer-big,
            something so ambitious and inspiring that it would summon the
            masses. The only problem was, we knew that we couldn’t tackle
            something that big. Institutions and corporations pour billions of
            dollars into solving big healthcare problems, and they’ve barely
            made a dent. We were trying to bite off more than we could chew. But
            this whole thing had to be perfect.
          </p>
          <p>
            I was so obsessed with this idea of what MedLaunch could be that I
            seemed to forget what MedLaunch was, that is, it was nothing at all.
            I seemed to like the idea of MedLaunch more than the actual work of
            making it happen. This went on for about the entirety of my junior
            year, until it hit us at the end of the summer. Our goal was to run
            the first Biodesign Challenge my senior year, but by the end of that
            summer before my senior year we had next to nothing prepared. We
            were so close to giving up, to accepting that it was a good thought
            experiment but it wasn’t going to happen. After all, why should we
            work so hard on this thing our last year of college, only to leave
            and never see it again.
          </p>
          <p>
            We needed to scale back. We needed to start with something small,
            something specific, something to simply get us going. And then it
            happened. I remember us sitting in the library one summer evening,
            all about ready to call it a day, when Sujai walked in and told us
            that he met a guy named James. James wasn’t just any guy, but a guy
            with quadriplegia who wanted to create technologies to overcome his
            own limitations. He had hundreds of ideas but didn’t have the
            resources to build them. He wanted to work with someone to bring his
            ideas to life. And there were others like him. It was perfect. We
            jumped at the opportunity to meet them, and they soon became known
            to us as our community partners.
          </p>
          <p>
            We went into that school year with a newfound scrappiness. No longer
            were we paralyzed by an incessant need for perfection, or holding on
            to some idea of what MedLaunch was supposed to be. We didn’t need to
            be perfect. We simply needed to be good enough.
          </p>
          <p>
            The Biodesign Challenge evolved into less of a competition and more
            of a collaborative effort between all the teams. Perhaps this was
            because of our lack of funding that made it difficult to come up
            with any hefty prizes, but whatever the case, people seemed more
            driven by the desire to do good than by the incentive of winning an
            award. We made a lot of mistakes that first year, and we worried
            about our ability to retain members. But despite those mistakes,
            people still stuck with us. They believed in what MedLaunch stood
            for and saw the possibility of what MedLaunch could be. And by the
            end of that year, we had three prototypes from three teams. Sure,
            they might not have been masterpieces, but they were something.
            Something that we could build upon.
          </p>
          <p>
            I believe that it was this scrappiness, mixed with a healthy dose of
            foolishness, that kept us going that first year. But it was the
            early members who fell in love with MedLaunch that carried us
            forward. Even when faced with the prospect of going remote in only
            MedLaunch’s third year, they still persisted and oversaw the
            completion of six working prototypes that consisted of both
            assistive physical devices and software applications.
          </p>
          <p>
            As MedLaunch continues to grow and evolve, I’m excited to see the
            interesting products that students create. At its best, I believe
            that MedLaunch is a community of earnest people with a shared love
            for science, technology and human-centered design. It’s a place to
            play, to tinker and explore, to try and fail, and then to try again.
          </p>
          <p>
            There is much I still hope to see MedLaunch achieve. I want to see
            MedLaunch take on bigger and better projects. I want students to
            build things that truly help others and that they can be proud of.
            Maybe some teams can even go on to start their own companies and
            raise millions in funding. But above all, I hope that students can
            enjoy the ride that being part of such an organization takes you on.
            For it was the process of taking MedLaunch from nothing to something
            that I hold most dear. The friendships I made, the lessons I
            learned. It was those early champions that made MedLaunch what it
            was and set the groundwork for what it could become. It was James,
            Jeff and Courtney who invited us into their homes and gave so much
            of their time to help us build those first prototypes. In spite of
            the challenges they faced in their daily lives, they brought forth a
            sense of optimism and resilience that inspired us to do better and
            keep pushing forward.
          </p>
          <p>
            And it was my friends, who were willing to take a chance and devote
            themselves to something that was already a meme to our peers and was
            likely to fail. I’ll admit, there was no shortage of times when they
            would frustrate me to no end. I was frequently on Mikey’s case about
            not doing enough and badgering him to put on this workshop or that.
            I would consistently bother Ashish about improving the website and
            would demand of him to do a better job mentoring teams when their
            progress slowed. And then there was Saswat, who seemed to only show
            up when he was going to hang out with the girl he was then seeing,
            who just so happened to live right across the street.
          </p>
          <p>
            I was forever worrying about the future, while they seemed concerned
            about nothing but the present. But it was they who actually
            interacted with the other members and extended their encouragement
            and enthusiasm. It was they who made the meetings fun. The witty
            banter, the joking and jostling, albeit I felt they were being
            unproductive, they countered my solemn demeanor and established a
            sense of play that got people coming back. While I schemed about the
            future, they bonded with others and helped them work on their
            projects, whether it was debugging a problem or pointing a team to
            the right resources. Especially Mikey, who always seemed to be
            simultaneously working on none of the projects and all of the
            projects.
          </p>
          <p>
            It was Roseanne that understood what we were trying to do and
            created the most mesmerizing graphics that got us excited and made
            MedLaunch no longer just an idea, but something tangible. And when
            Roseanne couldn’t continue working on MedLaunch, she introduced us
            to Molly, who committed herself wholeheartedly to the organization
            and churned out beautiful pieces of art on Adobe Illustrator faster
            than I’d ever seen. And it was Sujai, who despite his reserved
            disposition, and his willingness to take a back seat so that I could
            shine in the spotlight, cared more about MedLaunch than anyone else.
            It was he who sustained the heart and vitality of the organization
            into the second year and who, to this day, spends more time than
            anyone else giving back to MedLaunch. It was the commitment of these
            early believers that gave me the conviction that what we were doing
            meant something, even if only to a few.
          </p>
          <p>
            The amalgamation of such characters and the chaos that ensued, that
            is what I miss the most. Amidst the drowning feeling that we weren’t
            doing enough, that we weren’t going to be good enough, and finally
            that we didn’t accomplish enough, I took these things for granted.
            But I only believe that those things happened because we gave
            ourselves to this organization and allowed that spirit of play to
            blossom. I hope that for future MedLaunchers, you can give yourself
            to MedLaunch like we once did. Because only then will you get out
            what you seek, and so much more.
          </p>
        </div>
      </div>
    </div>
  );
}

export default DavidsStory;
