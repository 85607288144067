import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../templates/Header';
import './OurStory.css';
import aboutHeaderIcon from './aboutHeaderIcon.svg';
import founders from './aboutImgs/founders.png';

function OurStory() {
  return (
    <div>
      <div className="our-story-header-container">
        <Header title="Our Story" img={founders} imgSvg={aboutHeaderIcon} />
      </div>
      <div className="story-container">
        <div className="section">
          <p>
            MedLaunch was founded by a group of friends who wanted to create
            better opportunities for students to innovate in the biomedical
            space at the University of Michigan. What started as an idea for a
            health hackathon quickly evolved into so much more. They decided to
            create a yearlong program they called the Biodesign Challenge, where
            students would design, build and test a product that solves a
            problem in healthcare. The only problem was they couldn’t figure out
            what exactly to work on.
          </p>
          <div className="row py-2">
            <p className="col-md-6">
              Fortunately, Sujai, one of the founders, met James, a quadraplegic
              who lived two floors down from him. He had hundreds of ideas but
              didn’t have the resources to build them. He wanted to work with
              someone to bring his ideas to life. Through James, we met others
              with different limitations who shared a desire to create and turn
              their misfortunes into sources of inspiration. They embodied the
              spirit that we were looking for, and they soon became our first
              community partners. Our goal was to work with them to create
              assistive technologies that could help them live more independent
              lives.
            </p>
            <div className="col-md-6">
              <img
                src={require('./aboutImgs/james_and_students.png')}
                alt="Innovate"
                width="100%"
              />
            </div>
          </div>
          <div className="row py-2 reverse-direction">
            <div className="col-md-6">
              <img
                src={require('./aboutImgs/festifall.jpeg')}
                alt="Innovate"
                width="100%"
              />
            </div>
            <p className="col-md-6">
              From the start, the founding team strove to create a
              multidisciplinary community of people that were eager to build and
              help others. They believed that anyone with the right attitude and
              opportunities could innovate, and thus the first members came from
              various colleges and schools, namely CoE, LSA, STAMPS and more. As
              we worked with the community partners, human-centered design
              quickly became a core value of MedLaunch, along with a desire to
              design beautiful products and exercise creativity.
            </p>
          </div>
          <div className="row py-2">
            <p className="col-md-8">
              The first year was far from perfect. Not too long into the
              process, MedLaunch lost its initial spark and was stricken with a
              sort of malaise. From spotty attendance, to a growing insecurity
              that we wouldn’t meet the high expectations we set for ourselves,
              it felt like MedLaunch was on the edge of collapse. Nevertheless,
              the members bonded over their shared struggles and their belief in
              what MedLaunch could be. It was often the unproductive times at
              meetings, where people fooled around and cracked jokes, that were
              often the most fruitful to establishing the sense of community
              everyone longed for and needed to help them through the tough
              times. Through a total team effort, people powered through and by
              the year’s end, each team had produced a prototype and presented
              it to the community partners in a final showcase. No one was in
              love with what they had made, but it was a start. A place to grow
              from and improve upon, and proof that they could actually build
              something.
            </p>
            <div className="col-md-4">
              <img
                src={require('./aboutImgs/mikey_ashish.png')}
                alt="Innovate"
                width="100%"
              />
            </div>
          </div>
          <div className="row py-2 reverse-direction">
            <div className="col-md-6">
              <img
                src={require('./aboutImgs/ashish_david_action_shot.png')}
                alt="Innovate"
                width="100%"
              />
            </div>
            <p className="col-md-6">
              It was time for most of the founding team to depart, but with a
              renewed sense of confidence after surviving the rough waters of
              year one, the new leadership was determined to make MedLaunch
              better. This year, the projects were more exciting and people
              embraced a new level of creativity as they iterated over ideas
              with sketches and fabrications. Teams were on the verge of
              finishing up their projects when suddenly the pandemic forced
              everyone to go remote. Once again, MedLaunch’s survival was on the
              line.
            </p>
          </div>
          <p>
            While many other organizations cancelled meetings for the rest of
            the year, the MedLaunch leadership understood how important it was
            to maintain a sense of community. They quickly adapted the club to
            work virtually and members designed interactive blogs and videos to
            showcase their efforts. Students did their best to finish up their
            projects, but people couldn’t help but wonder what could have been
            if they were given a few more months to work in person.
          </p>

          <div className="row py-2">
            <div className="col-md-6">
              <img
                src={require('./aboutImgs/tailgate.png')}
                alt="Innovate"
                width="100%"
              />
            </div>
            <div className="col-md-6">
              <p>
                Heading into the third year, it became clear that MedLaunch was
                either going to fully adapt to remote work or die. Surprisingly,
                this turned out to be MedLaunch’s best year yet. More members
                joined than ever before, and teams maintained a high level of
                enthusiasm and camaraderie despite the physical separation.
                Teams put a lot of work into their projects, and the final
                prototypes were well designed, enough so for two teams to even
                pursue patents.
              </p>
              <p>
                Now MedLaunch is currently embarking on it’s fourth year. For
                the early members who stayed, they are now seniors and have
                grown into leaders of the organization. After over a year of
                being apart, there is greater meaning in the moments members
                spend together. Our goal moving forward is to continue to
                strengthen our community and to create products that are better
                than anything we’ve done before. It’s to love what we do and
                share that passion with others.
              </p>
            </div>
          </div>
          <p>
            MedLaunch is now at its most exciting point yet. There is so much to
            be done and so many opportunities await. The rest of the story is
            yet to be written. The only question is: who will write the next
            chapter?
          </p>
          <div className="circles-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <p>
            David Chang, co-founder and former president of MedLaunch, shares
            his reflections on the founding of MedLaunch. To check out his
            story, go{' '}
            <Link to="/founding" style={{ color: '#007bff' }}>
              here
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
