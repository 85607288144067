import React from 'react';
import './Header.css';
import headerBgrnd from './headerBgrnd.png';
import headerDots from './headerDots.svg';
import headerIcon from './headerIcon.svg';

function Header(props) {
  const { title, img, imgSvg } = props;
  const imgSrc = img ? img : headerBgrnd;
  const imgIconSrc = imgSvg ? imgSvg : headerIcon;
  return (
    <div className="section header-container">
      <div className="img-container">
        <img src={imgSrc} alt="Header background" width="100%" />
        <div className="centered-left" style={{ fontSize: '4vw' }}>
          <h3>{title}</h3>
        </div>
        <div className="top-right">
          <img src={headerDots} alt="Header background" width="100%" />
        </div>
        <div className="bottom-right">
          <img
            className="img-svg-icon"
            src={imgIconSrc}
            alt="Header background"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
