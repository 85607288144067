import React from 'react';

function RowInfo(props) {
  const { title, desc, img } = props;
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <img src={require('./rowInfoImgs/' + img)} alt={title} width="100%" />
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div>
          <h3>{title}</h3>
        </div>
        <div>
          <h6>{desc}</h6>
        </div>
      </div>
    </div>
  );
}

export default RowInfo;
