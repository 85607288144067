import webImg from './newProjectImgs/website.png'


const NewProjectInfo = [
    // {
    //     teamName: 'Adaptive Bowling',
    //     shortDesc: 'A dynamic bowling ramp for wheelchair users',
    //     longDesc: 'Lorem Ipsum Lorem Ipsum',
    //     projImg: webImg,
    // },
    // {
    //     teamName: 'Adaptive Guitar',
    //     shortDesc: 'An accessible guitar for people with hand and upper body mobility disabilities',
    //     longDesc: 'Lorem Ipsum Lorem Ipsum',
    //     projImg: webImg,
    // },
    // {
    //     teamName: 'Adaptive Umbrella',
    //     shortDesc: 'A hands-free umbrella compatible with crutches',
    //     longDesc: 'Lorem Ipsum Lorem Ipsum ',
    //     projImg: webImg,
    // },
    // {
    //     teamName: 'Hair Curler',
    //     shortDesc: 'Adaptive curling wand for people with decreased grip strength to prevent burns and promote independence',
    //     longDesc: 'Lorem Ipsum Lorem Ipsum ',
    //     projImg: webImg,
    // },
    // {
    //     teamName: 'Mental Wellness App',
    //     shortDesc: 'A mental wellness app that tracks user input and outputs Ann Arbor-based recommendations',
    //     longDesc: 'Lorem Ipsum Lorem Ipsum',
    //     projImg: webImg,
    // },
    // {
    //     teamName: 'Measuring Device',
    //     shortDesc: 'A device that measures liquids for patients that are blind',
    //     longDesc: 'Lorem Ipsum Lorem Ipsum',
    //     projImg: webImg,
    // },
    // {
    //     teamName: 'Pill Tracker App',
    //     shortDesc: 'Digital pill tracker for patients with memory loss',
    //     longDesc: 'Lorem Ipsum Lorem Ipsum',
    //     projImg: webImg,
    // },
]

export default NewProjectInfo;