import React from 'react';
import awardLightBulb from './awardLightBulb.svg';
import tedxAward from './tedxAward.png';

function Award() {
  return (
    <div className="award-container">
      <div className="section">
        <div className="row no-gutters">
          <div
            className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
            style={{
              backgroundColor: 'white',
              margin: '2rem 0px',
              textAlign: 'left',
            }}
          >
            <div className="row align-items-center h-100 mx-4 p-0">
              <div className="w-100">
                <h3>We won an award!</h3>
                <h6>
                  This February, we won the Tedx UofM Innovation Award. Our very
                  own Sujai Arakali and Madison Caldwell got to share our story
                  and mission with the great Tedx team and their wonderful
                  patrons. Thanks Tedx, we&apos;ve got big plans!
                </h6>
                <p className="my-2">
                  <a
                    style={{ color: '#17C2A0', textDecoration: 'underline' }}
                    href="https://youtu.be/EfyYCzeFuRY?t=7018"
                  >
                    Watch Video
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 my-auto">
            <img src={tedxAward} alt="Award" width="100%" />
            <div className="award-light-bulb">
              <img src={awardLightBulb} alt="Award light bulb" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Award;
