import React from 'react';
import './BiodesignRow.css';
import biodesignFullArrow from './biodesignFullArrow.svg';
import biodesignSmallArrow from './biodesignSmallArrow.svg';

function BiodesignRow({ phaseNum, title, desc, imgMid, imgSide }) {
  return (
    <div className="row biodesign-row-container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <h3 style={{ color: '#E33C2E' }}>Phase {phaseNum}</h3>
          <h4>{title}</h4>
          <p>{desc}</p>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 pt-3 py-md-0">
          <img
            src={require('' + imgMid)}
            alt={'Phase ' + phaseNum + ' center graphic'}
            width="100%"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 pt-3 py-md-0">
          <img
            src={require('' + imgSide)}
            alt={'Phase ' + phaseNum + ' right graphic'}
            width="100%"
          />
        </div>
      </div>
      <div className="arrow-container w-100">
        <div className="biodesign-arrow-container d-none d-md-block mx-auto">
          <img
            src={biodesignFullArrow}
            alt={'Phase ' + phaseNum + ' arrow'}
            width="100%"
          />
        </div>
        <div className="biodesign-small-arrow-container d-md-none mx-auto pt-3 py-sm-0">
          <img
            src={biodesignSmallArrow}
            alt={'Phase ' + phaseNum + ' mobile arrow'}
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default BiodesignRow;
