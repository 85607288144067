import React from 'react';
import Header from '../templates/Header.js';
import RowInfo from '../templates/RowInfo.js';
import './Biodesign.css';
import BiodesignRow from './BiodesignRow.js';
import biodesignHeaderBgrnd from './biodesignHeaderBgrnd.png';
import headerIcon from './biodesignHeaderIcon.svg';

function Biodesign() {
  const aboutRows = [
    {
      title: 'The Challenge',
      desc: "The Biodesign Challenge is a full-year program that every MedLaunch member participates in. Students are organized into small teams, and each team is paired with a community partner. Our community partners are disabled members of the Ann Arbor area. Throughout the course of the year, MedLaunch teams will work with their community partners to design, build, and test assistive technology that can solve problems commonly faced by that individual. The first semester of the program is dedicated to identifying the community partner's needs, developing an idea that addresses those needs, and creating an initial prototype of that idea. During the second semester, teams will iterate upon their initial prototype. In each iteration, teams will get feedback from their community partner, adapt their idea, then create a new prototype. At the end of the year, we hold a Final Showcase, where teams present their final deliverables to the other MedLaunch teams and community partners.",
      img: 'biodesignImgs/paintability.png',
    },
  ];

  const phaseRows = [
    {
      title: 'Identify the need',
      desc: 'Students meet with their community partner to learn about their needs and focus on a problem to tackle.',
      imgSide: './biodesignPhaseImgs/phase1Img.png',
      imgMid: './biodesignPhaseImgs/phase1Dood.svg',
    },
    {
      title: 'Generate a Concept',
      desc: 'Think of a really good idea that you and your community partner are excited about.',
      imgSide: './biodesignPhaseImgs/phase2Img.png',
      imgMid: './biodesignPhaseImgs/phase2Dood.svg',
    },
    {
      title: 'Design Development',
      desc: 'Research and develop your idea further.',
      imgSide: './biodesignPhaseImgs/phase3Img.png',
      imgMid: './biodesignPhaseImgs/phase3Dood.svg',
    },
    {
      title: 'Design Review',
      desc: 'Share your idea with faculty guests, other MedLaunch teams, and officers to get feedback to strengthen your concept.',
      imgSide: './biodesignPhaseImgs/phase4Img.png',
      imgMid: './biodesignPhaseImgs/phase4Dood.svg',
    },
    {
      title: 'Prototyping and Testing',
      desc: 'Create dynamic models. Test, refine, and make it awesome. Continue to collaborate with the MedLaunch community for resources, funding, and exciting opportunities.',
      imgSide: './biodesignPhaseImgs/phase5Img.png',
      imgMid: './biodesignPhaseImgs/phase5Dood.svg',
    },
    {
      title: 'Showcase',
      desc: 'Present your final product to your friends, members, faculty, and community partners. Get some recognition, and celebrate an amazing year of innovation with your MedLaunch Family.',
      imgSide: './biodesignPhaseImgs/phase6Img.png',
      imgMid: './biodesignPhaseImgs/phase6Dood.svg',
    },
  ];

  return (
    <div className="biodesign-container">
      <Header
        className="top-right"
        title="Biodesign"
        img={biodesignHeaderBgrnd}
        imgSvg={headerIcon}
      />
      <div className="section biodesign-info-container">
        {aboutRows &&
          aboutRows.map((biodesignInfo, index) => (
            <RowInfo key={index} {...biodesignInfo} />
          ))}
      </div>
      <div className="biodesign-process-container pb-5">
        <div className="section">
          <h2 style={{ padding: '2rem', textAlign: 'center' }}>The Process</h2>
          {phaseRows &&
            phaseRows.map((phase, index) => (
              <BiodesignRow key={index} phaseNum={index + 1} {...phase} />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Biodesign;
