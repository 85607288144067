import React from 'react';
import Header from '../templates/Header.js';
import ApplicationProcess from './ApplicationProcess.js';
import './Contact.css';
import ContactSection from './ContactSection.js';
import Map from './Map.js';

function Contact() {
  return (
    <div className="contact-container">
      <Header title="Get Involved" />
      <ApplicationProcess />
      {/* <ContactSection /> */}
      <Map />
    </div>
  );
}

export default Contact;
