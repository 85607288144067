import React from 'react';
import landingArt from './landingArt.png';

function Intro() {
  return (
    <div className="section intro-container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <div className="d-none d-md-block" style={{ height: '25%' }}></div>
          <div>
            <h1>Designing the Future of Healthcare.</h1>
            <button className="intro-apply" style={{ width: '140px' }}>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdfb_g-KthSs4poJfIA0l8tsapl9VA-7CHONiElff8zmFL8fg/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                Apply Today!
              </a>
            </button>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <div className="d-block d-md-none" style={{ height: '2rem' }}></div>
          <img src={landingArt} alt="Landing page" width="100%" />
        </div>
      </div>
    </div>
  );
}

export default Intro;
