import React from 'react';
import mapUnion from './mapStudentUnion.PNG';

function Map() {
  return (
    <div className="map-container">
      <div className="section">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <h3>We’ll be here.</h3>
            <h6>
              Michigan Union
              <br />
              530 S State St
              <br />
              Ann Arbor, MI, 48109
            </h6>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <img src={mapUnion} alt="Union Map" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;
