import React from 'react';
import Header from '../templates/Header';
import './About.css';
import aboutHeaderBgrnd from './aboutHeaderBgrnd.png';
import aboutHeaderIcon from './aboutHeaderIcon.svg';

function Mission() {
  return (
    <div className="about-container">
      <Header
        title="Our Mission"
        img={aboutHeaderBgrnd}
        imgSvg={aboutHeaderIcon}
      />
      <div className="mission-container">
        <h4>
          Our mission is to innovate in healthcare. We work with medical experts
          and community partners to create new technologies that improve health
          outcomes and enable people to live better.
        </h4>
        <br />
        <p>
          We believe that technology can help people overcome their limitations
          and live more fulfilling lives, but those technologies won&apos;t
          appear on their own. It&apos;s up to us to bridge the gaps we see in
          healthcare today and to build the future we want to live in. By
          working together and designing with compassion, we can create a world
          that&apos;s meant for all of us.
        </p>
        <p>
          Below are our core goals for the organization. We aim to design
          beautiful products, empower students to grow and create, and advance
          the field of healthcare.
        </p>
      </div>

      <div className="section about-info-container">
        <div className="row">
          <div className="small col-12 col-md-6">
            <img
              src={require('./aboutImgs/design.png')}
              alt="Design"
              width="100%"
            />
          </div>
          <div className="textBox design col-12 col-md-6">
            <div className="title">
              <h3>Design</h3>
            </div>
            <div className="desc">
              <h6>
                We refer to our clients as community partners because MedLaunch
                strongly believes that project teams should design with their
                community partner instead of for them. We involve these partners
                in every step of the design process, from ideation to
                prototyping. As a result, our teams create solutions that are
                customized to accommodate the specific needs of their community
                partner.
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small col-12 col-md-6">
            <img
              src={require('./aboutImgs/educate.png')}
              alt="Educate"
              width="100%"
            />
          </div>
          <div className="textBox educate col-12 col-md-6">
            <div className="title">
              <h3>Educate</h3>
            </div>
            <div className="desc">
              <h6>
                We aim to provide our members with opportunities to gain
                hands-on design experience while working collaboratively with
                peers from all across campus. Members get to learn and develop
                skills relevant to the design process while also gaining
                valuable insight on the leading edge of biotechnology.
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <img
              src={require('./aboutImgs/innovate.png')}
              alt="Innovate"
              width="100%"
            />
          </div>
          <div className="textBox innovate col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="title">
              <h3>Innovate</h3>
            </div>
            <div className="desc">
              <h6>
                Our projects are innovate and impactful, and they present novel
                solutions to problems faced by real people. The MedLaunch
                community is filled with creative thinkers that are eager and
                enthusiastic about tackling challenges.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
