import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import DavidsStory from './components/about/DavidsStory';
import Mission from './components/about/Mission';
import OurStory from './components/about/OurStory';
import Team from './components/about/Team';
import Faq from './components/about/Faq'
import Biodesign from './components/biodesign/Biodesign';
import Contact from './components/contact/Contact';
import Error404 from './components/error/Error404';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Nav from './components/navigation/Nav';
import Projects from './components/projects/Projects';

function App() {
  return (
    <Router>
      <div className="App" style={{ '--app-color': '', '--app-color-2': '' }}>
        <ScrollToTop />
        <Nav />
        <div className="body-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/team" element={<Team />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/origins" element={<OurStory />} />
            <Route path="/founding" element={<DavidsStory />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/biodesign-challenge" element={<Biodesign />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route element={Error404} status={404} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
