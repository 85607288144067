import React from 'react';
import './About.css';
import aboutSquareInfo from './AboutSquareInfo';
import OfficerSquare from './OfficerSquare';

function Team() {
  var aboutSquareIdx = 0;

  return (
    <div className="about-container">
      <div className="officer-square-container">
        {aboutSquareInfo &&
          aboutSquareInfo.map((section, idx) => (
            <div key={idx} className="section">
              <div className="row">
                <div className="w-100">
                  <h2 className="title">{section.sectionTitle}</h2>
                  <h5 style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
                    {section.sectionDesc}
                  </h5>
                </div>
                <div className="card-deck w-100">
                  {section.sectionElts &&
                    section.sectionElts.map((square) => {
                      aboutSquareIdx += 1;
                      return (
                        <OfficerSquare
                          key={aboutSquareIdx}
                          modalId={aboutSquareIdx}
                          color={section.sectionColor}
                          {...square}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Team;
