import React from 'react';

// import './OfficerSquare.css';

function FaqAccordian({ idx, question, answer }) {
  return (
    <div className="faq-section">
      <input type="checkbox" id={idx} checked />
      <label className="item-question" htmlFor={idx}>
        <span style={{ font: 'Poppins', fontSize: '1.85rem' }}>{question}</span>
      </label>
      <div className="item-answer px-4 py-1">
        {answer &&
          (Array.isArray(answer)
            ? answer.map((innerFaq, innerIdx) => {
                const id = idx + '.' + innerIdx;
                return (
                  <div key={innerIdx} className="inner-item-answer">
                    <input type="checkbox" id={id} />
                    <label className="item-question" htmlFor={id}>
                      {innerFaq.question}
                    </label>
                    <div className="item-answer px-4 py-1">
                      {innerFaq.answer}
                    </div>
                  </div>
                );
              })
            : answer)}
      </div>
    </div>
  );
}

export default FaqAccordian;
