import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      {/* <!-- FIXME: Align footer items on bottom --> */}
      <div className="container-fluid" style={{ maxWidth: '980px' }}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <h4>MedLaunch</h4>
            <h5>Stay Tuned</h5>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/medlaunchmichigan/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook-official"></i>
              </a>
              <a
                href="https://www.instagram.com/medlaunch/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                href="https://www.medium.com/medlaunch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-medium"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/medlaunch/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <h5>More Questions? We&apos;ve got answers.</h5>
            <div className="row pt-0">
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 pt-2">
                <button className="footerBtn">
                  <a href="/faq">FAQs</a>
                </button>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 pt-2">
                <button className="footerBtn">
                  <a
                    href="mailto:medlaunch-info@umich.edu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Email Us
                  </a>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <a
                  href="mailto:medlaunch-info@umich.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  medlaunch-info@umich.edu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
