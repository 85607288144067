import React from 'react';
import Header from '../templates/Header.js';
import './About.css';
import FaqAccordian from './FaqAccordian';
import faqsInfo from './FaqsInfo';

import aboutHeaderIcon from './aboutHeaderIcon.svg';
import founders from './aboutImgs/founders.png';

function Faq() {
  return (
      <div className="our-story-header-container">
        <Header 
          title="FAQ" 
          img={founders} 
          imgSvg={aboutHeaderIcon}
        />
        <div id="FAQs" className="about-faq-container">
          <div className="section">
            <div className="faqs-container">
              {faqsInfo &&
                faqsInfo.map((faq, idx) => (
                  <FaqAccordian key={idx} idx={idx} {...faq} />
                ))}
            </div>
          </div>
        </div>
      </div>
  );
}

export default Faq;
