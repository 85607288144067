import React from 'react';
import overviewImg from './overviewImg.png';

function Overview() {
  return (
    <div className="overview-container">
      <div className="section">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <img src={overviewImg} alt="Overview" width="100%" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <h3>What’s MedLaunch?</h3>
            <h6>
              MedLaunch is a community of students at the University of Michigan
              passionate about healthcare and biomedical innovation. Students of
              different backgrounds from Engineering, LSA, STAMPS, the School of
              Music, and many more participate in a year-long Biodesign
              Challenge to develop assistive technologies for local community
              partners with disabilities, in an effort to solve problems
              commonly faced by these individuals. MedLaunch has enabled
              students to become leaders on campus and connect with people who
              are curious and want to make a difference, so that together we can
              make the world a healthier place.
            </h6>
            <a
              style={{ color: '#17C2A0', textDecoration: 'underline' }}
              href="https://www.youtube.com/watch?v=g4hzuAo_nn4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p style={{ paddingTop: '1rem' }}>Hear our story</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
