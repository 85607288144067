import React from 'react';
import videoArrow from './videoArrow.svg';
import videoDots from './videoDots.svg';

// import useFitVid from '../../hooks/useFitVid.js';

function OurVideo() {
  // useFitVid();
  return (
    <div className="video-container">
      <div className="section">
        <div className="mx-auto" style={{ width: '75%' }}>
          <div className="fitVids-wrapper">
            <iframe
              title="MedLaunch Video"
              width="800"
              height="450"
              src="https://www.youtube.com/embed/KCmgCmb7eZ0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="video-container-text">
              <h4>Watch our video!</h4>
              <div>
                <img src={videoArrow} alt="arrow" />
              </div>
            </div>
            <div className="video-container-dots">
              <img src={videoDots} alt="dots white" />
              <img src={videoDots} alt="dots white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurVideo;
