import React from 'react';
import Header from '../templates/Header';
import './About.css';
import aboutSquareInfo from './AboutSquareInfo';
import FaqAccordian from './FaqAccordian';
import faqsInfo from './FaqsInfo';
import OfficerSquare from './OfficerSquare';
import aboutHeaderBgrnd from './aboutHeaderBgrnd.png';
import aboutHeaderIcon from './aboutHeaderIcon.svg';

function About() {
  var aboutSquareIdx = 0;

  return (
    <div className="about-container">
      <Header
        title="Our Mission"
        img={aboutHeaderBgrnd}
        imgSvg={aboutHeaderIcon}
      />
      <div className="section">
        <h4>Our mission is to build assistive tech for people.</h4>
        <br />
        <h4>
          We believe that everyone has a right to life, liberty and the pursuit
          of happiness.
        </h4>
      </div>

      <div className="section about-info-container">
        <div className="row">
          <div className="small col-12 col-md-6">
            <img
              src={require('./aboutImgs/design.png')}
              alt="Design"
              width="100%"
            />
          </div>
          <div className="textBox design col-12 col-md-6">
            <div className="title">
              <h3>Design</h3>
            </div>
            <div className="desc">
              <h6>
                We refer to our clients as community partners because MedLaunch
                strongly believes that project teams should design with their
                community partner instead of for them. We involve these partners
                in every step of the design process, from ideation to
                prototyping. As a result, our teams create solutions that are
                customized to accommodate the specific needs of their community
                partner.
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small col-12 col-md-6">
            <img
              src={require('./aboutImgs/educate.png')}
              alt="Educate"
              width="100%"
            />
          </div>
          <div className="textBox educate col-12 col-md-6">
            <div className="title">
              <h3>Educate</h3>
            </div>
            <div className="desc">
              <h6>
                We aim to provide our members with opportunities to gain
                hands-on design experience while working collaboratively with
                peers from all across campus. Members get to learn and develop
                skills relevant to the design process while also gaining
                valuable insight on the leading edge of biotechnology.
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <img
              src={require('./aboutImgs/innovate.png')}
              alt="Innovate"
              width="100%"
            />
          </div>
          <div className="textBox innovate col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="title">
              <h3>Innovate</h3>
            </div>
            <div className="desc">
              <h6>
                Our projects are innovate and impactful, and they present novel
                solutions to problems faced by real people. The MedLaunch
                community is filled with creative thinkers that are eager and
                enthusiastic about tackling challenges.
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="officer-square-container">
        {aboutSquareInfo &&
          aboutSquareInfo.map((section, idx) => (
            <div key={idx} className="section">
              <div className="row">
                <div className="w-100">
                  <h2 className="title">{section.sectionTitle}</h2>
                  <h5 style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
                    {section.sectionDesc}
                  </h5>
                </div>
                <div className="card-deck w-100">
                  {section.sectionElts &&
                    section.sectionElts.map((square) => {
                      aboutSquareIdx += 1;
                      return (
                        <OfficerSquare
                          key={aboutSquareIdx}
                          modalId={aboutSquareIdx}
                          color={section.sectionColor}
                          {...square}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div id="FAQs" className="about-faq-container">
        <div className="section">
          <h1>FAQ</h1>
          <div className="faqs-container">
            {faqsInfo &&
              faqsInfo.map((faq, idx) => (
                <FaqAccordian key={idx} idx={idx} {...faq} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
