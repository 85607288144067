import React, { useEffect, useState } from 'react';
import Header from '../templates/Header.js';
import NewProjects from './NewProjects.js';
import ProjectCard from './ProjectCard.js';
import projectInfo from './ProjectInfo';
import './Projects.css';
import projectHeaderBgrnd from './projectHeaderBgrnd.png';
import projectHeaderIcon from './projectHeaderIcon.svg';

function Projects() {
  const [selectedProjectIdx, setSelectedProjectIdx] = useState(0);
  const [selectedProject, setSelectedProject] = useState(
    projectInfo[selectedProjectIdx],
  );
  useEffect(() => {
    setSelectedProject(projectInfo[selectedProjectIdx]);
  }, [selectedProjectIdx]);

  return (
    <div>
      <Header
        title="Our Projects"
        img={projectHeaderBgrnd}
        imgSvg={projectHeaderIcon}
      />
      <div className="projects-container">
        <NewProjects />
        <div className="section">
          <h1 style={{ textAlign: 'center' }}>Past Projects</h1>
          <div className="projects-container-header col-xs-12 col-sm-12 col-md-5 col-lg-5">
            <h1 className="pb-3">
              <div className="btn-group">
                <button
                  className="btn btn-secondary btn-lg dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {selectedProject.sectionTitle}
                </button>
                <div className="dropdown-menu">
                  {projectInfo &&
                    projectInfo.map((section, idx) => (
                      <button
                        key={idx}
                        className="dropdown-item"
                        style={{ fontSize: '1.2rem', lineHeight: '24px' }}
                        onClick={() => {
                          setSelectedProjectIdx(idx);
                        }}
                      >
                        {section.sectionTitle}
                      </button>
                    ))}
                </div>
              </div>
              {/* <select
                className='w-100'
                value={selectedProjectIdx}
                onChange={(e) => setSelectedProjectIdx(e.target.value)}
              >
                {projectInfo &&
                  projectInfo.map((section, idx) => (
                    <option key={idx} value={idx}>
                      {section.sectionTitle}
                    </option>
                  ))}
              </select> */}
            </h1>
          </div>
          <div className="body-container container">
            <div className="card-columns pt-3">
              {selectedProject &&
                selectedProject.teams &&
                selectedProject.teams.map((team, index) => (
                  <ProjectCard key={index} {...team} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
