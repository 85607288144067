import React from 'react';
import './ProjectCard.css';

function ProjectCard(props) {
  // teamImg, teamHoverImg teamLink
  const { teamName, teamDesc, teamImg, projImg, link } = props;
  return (
    <div className="card">
      <img
        className="card-img-top"
        src={require('' + teamImg)}
        alt={teamName}
      />
      <div className="card-body">
        <h5 className="card-title">{teamName}</h5>
        <div className="row pt-2">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8">
            <p className="card-text">{teamDesc}</p>
            <button className="project-card-readmore">
              <a href={link} target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </button>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <img
              className="card-img-top project-card-img"
              src={require('' + projImg)}
              alt={teamName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
