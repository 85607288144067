import React from 'react';
import brian from './brian.png';
import lilly from './lilly.png';
import mask_challenge from './mask_challenge.png';
import matt from './matt.png';

function MaskChallenge() {
  return (
    <div className="mask-container">
      <div className="section">
        <h3>We held the MedLaunch Mask Design Challenge!</h3>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <img src={mask_challenge} alt="mask" width="100%" />
          </div>
          <div className="fitVids-wrapper col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-3 mt-md-0">
            <iframe
              title="Mask Challenge Video"
              width="330"
              height="610"
              src="https://www.youtube.com/embed/RDqBaEnxfxY"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="pt-5">
          <h4>Matt Gaidica, Winner of the MedLaunch Mask Design Challenge:</h4>
          <p>
            &quot;Participating in the MedLaunch Mask Challenge was a really fun
            way to learn more and engage with a community being underserved at
            this unique moment in time. My doctoral work at Michigan focused
            largely on Parkinson&apos;s disease and has made me appreciate the
            hardships of living with limited (or absent) mobility. I think
            MedLaunch brings out the best in a special group of doers, creators,
            and problem solvers at U-M, and it was a real honor to work in
            solidarity with them on a great problem.&quot;
          </p>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-3 mt-md-0">
            <img src={matt} alt="mask" width="100%" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-3 mt-md-0">
            <img src={brian} alt="mask" width="100%" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-3 mt-md-0">
            <img src={lilly} alt="mask" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaskChallenge;
