const FaqsInfo = [
  {
    question: 'General',
    answer: [
      {
        question:
          'I have no experience on project teams. Can I still join MedLaunch?',
        answer:
          'Yes, we encourage all people to apply for MedLaunch regardless of experience, major, or background. A significant amount of time in the first semester will be spent teaching members design skills, so by the end of the year you will learn what it takes to be on a design team. MedLaunch is centered around learning and community, which means that all are welcome!',
      },
      {
        question: 'How do I apply?',
        answer:
          'The application process will begin in early September each year. There are two components: an application and a mini project. The mini projects are desinged so that any prospective MedLaunch member will be able to complete them, regardless of previous experience. Our goal throughout the application process is to assess your creativity, committment, and enthusiasm.',
      },
      {
        question: 'Is there a membership fee?',
        answer:
          'All MedLaunch members have an annual due of $10. We offer financial aid, so do not let this be a limiting factor in your decision to join MedLaunch.',
      },
      {
        question: 'How much of a time committment is MedLaunch?',
        answer:
          'MedLaunch members should expect to spend 5-7 hours a week on their projects. Our teams are expected to completely finish their project throughout the course of one school year, and our time committment reflects this large task.',
      },
      {
        question: 'Is there an attendance policy?',
        answer:
          'We require members to maintain a 75% attendance rate to MedLaunch meetings and events. If a member does not reach this requirement, they will be removed from their Biodesign Challenge team.',
      },
    ],
  },
  {
    question: 'Biodesign',
    answer: [
      {
        question: 'Do I get to choose what project team I am on?',
        answer:
          'When applying to MedLaunch you will fill out a preference form, where you will rank each project. We do our best to match each member to one of their top project choices.',
      },
      {
        question: 'When will we work on these projects?',
        answer:
          'MedLaunch hosts two mandatory, clubwide meetings on Wednesday evenings and Sunday afternoons. During these meetings, project teams will have a chance to work together on their projects. We will also host extra office hours and spaces for your teams as needed.',
      },
      {
        question: 'Who is this project for?',
        answer:
          'Each Biodesign Challenge team is assigned a community partner. You are designing your project specifically for that person, and it will be given to them at the end of the year. With these projects, you are helping your community partner live a more independent life.',
      },
      {
        question: 'Will we need to buy our own materials for our project?',
        answer:
          'MedLaunch will purchase any materials needed by each project team.',
      },
      {
        question: 'What is the Final Showcase?',
        answer:
          'We host an annual Final Showcase in April, where MedLaunch members present their projects to other club members, community partners, and University of Michigan affiliates. This showcase is meant to be a celebration of your efforts as a MedLaunch member.',
      },
    ],
  },
];

export default FaqsInfo;
