const AboutSquareInfo = [
  {
    sectionTitle: 'Core Officers',
    sectionDesc:
      'Meet our core team of innovators, who make MedLaunch possible.',
    sectionColor: '#E33B2D',
    sectionElts: [
      {
        name: 'Parth Patel',
        position: 'President',
        img: './officer-photos/partpat.jpeg',
        modalImg: './officer-photos/parth-fun1.jpg',
        linkedinLink: 'https://www.linkedin.com/in/parth-patel-umich2025/',
        email: 'partpat@umich.edu',
        description:
          "Parth is a senior studing computer science. He is interested in using technology to advance healthcare. Outside of school, he enjoys playing IM sports, and eating at restaurants with friends",
      },
      {
        name: 'Shruti Subramanian',
        position: 'Vice President',
        img: './officer-photos/subs.jpeg',
        modalImg: './officer-photos/shruti-fun1.jpg',
        linkedinLink: 'https://www.linkedin.com/in/shruti-subs/',
        email: 'subs@umich.edu',
        description:
          "Shruti is senior studying Neuroscience with a minor in Business and is hoping to work in the life sciences and medical sectors to positively impact those with a variety of health conditions. She currently works in a neuroscience research lab studying the neural circuitry behind motor disorders. In her free time, Shruti can be found running or hiking, reading a book, or hanging out with friends.",
      },
      {
        name: 'Caroline Dean',
        position: 'Finance Chair',
        img: './officer-photos/csdean.jpeg',
        modalImg: './officer-photos/csdean.jpeg',
        linkedinLink: '',
        email: 'csdean@umich.edu',
      },
      {
        name: 'Mason Teachman',
        position: 'Creative Chair',
        img: './officer-photos/mteach.jpg',
        modalImg: './officer-photos/mteach.jpg',
        linkedinLink: '',
        email: 'mteach@umich.edu',
      },
      {
        name: 'Isabel Comfort',
        position: 'Internal Chair',
        img: './officer-photos/icomfort.jpeg',
        modalImg: './officer-photos/icomfort.jpeg',
        linkedinLink: '',
        email: 'icomfort@umich.edu',
      },
      {
        name: 'Derek Fukuda',
        position: 'Logistics Chair',
        img: './officer-photos/dhfukuda.jpeg',
        modalImg: './officer-photos/dhfukuda.jpeg',
        linkedinLink: '',
        email: 'dhfukuda@umich.edu',
      },
      {
        name: 'Arsal Naeem',
        position: 'Professional Development',
        img: './officer-photos/arsaln.jpeg',
        modalImg: './officer-photos/arsaln.jpeg',
        linkedinLink: '',
        email: 'arsaln@umich.edu',
      },
      {
        name: 'Hane Eun',
        position: 'Community Outreach',
        img: './officer-photos/haneeun.jpg',
        modalImg: './officer-photos/haneeun.jpg',
        linkedinLink: '',
        email: 'haneeun@umich.edu',
      },
    ],
  },
  {
    sectionTitle: 'Project Managers',
    sectionDesc:
      'Meet our project managers, who will each lead a Biodesign Challenge team during the 2024-2025 school year.',
    sectionColor: '#1E2C55',
    sectionElts: [
      // {
      //   name: 'Derek Fukuda',
      //   position: 'Measuring Device',
      //   img: './officer-photos/derek_fukuda.jpg',
      //   modalImg: './officer-photos/derek_fukuda.jpg',
      //   description: '',
      // },
    ],
  },
  {
    sectionTitle: 'Faculty Sponsor',
    sectionDesc:
      'Introducing our faculty sponsor, who will provide support for our projects.',
    sectionColor: '#398BCD',
    sectionElts: [
      {
        name: 'Dr. Oluwaferanmi O. Okanlami',
        position: 'Faculty Sponsor',
        img: './officer-photos/okanlami_1.png',
        modalImg: './officer-photos/okanlami_2.png',
        fbLink: 'https://www.facebook.com/feranmi.okanlami',
        linkedinLink: 'https://www.linkedin.com/in/okanlami/',
        email: 'okanlami@med.umich.edu',
        description: '',
      },
    ],
  },
];

export default AboutSquareInfo;
