import React from 'react';
import './ApplicationProcess.css';
//import apply1 from './appProcessImgs/apply1.png';
import apply2 from './appProcessImgs/apply2.png';
import apply3 from './appProcessImgs/apply3.png';
import apply4 from './appProcessImgs/apply4.png';
import apply5 from './appProcessImgs/apply5.png';
import applicationRightArrow from './applicationRightArrow.svg';
import { Link } from 'react-router-dom';

function ApplicationProcess() {
  const appPhases = [
    /* {
      img: apply1,
      textHeader: "Meet us at Festifall, Northfest or on Social Media",
      textBody: "(Say hi and hear about our new projects!)"
    }, */
    {
      img: apply2,
      textHeader: 'Fill out our written application',
      textBody:
        "It's quick and no previous experience is required! \nFinished form due 9/10 at 11:59pm",
    },
    {
      img: apply3,
      textHeader: 'Group Interviews',
      textBody:
        'Helps us get a feel for how you work in a team setting!\n 9/12-13'
    },
    {
      img: apply4,
      textHeader: 'Have an coffee chat with us',
      textBody:
        'Allow us to get to know you and your MedLaunch goals!\n 9/15-21',
    },
    {
      img: apply5,
      textHeader: 'You’re in!',
      textBody:
        'Meet your team, community partner, and get to creating!\nDecisions released and Kickoff Meeting on 9/25!',
    },
  ];
  return (
    <div className="application-process-container">
      <div className="application-section">
        <div className="py-2">
          <h2 style={{ textAlign: 'center', paddingTop: '2rem' }}>
            The Application Process 2024
          </h2>
        </div>
        <div className="application-process-phases row w-100 align-items-center p-0 ml-md-5 mx-auto">
          {appPhases &&
            appPhases.map((phase, idx) => (
              <div
                key={idx}
                className="application-process-phase col-xs-12 col-sm-12 col-md-3 col-lg-3"
              >
                <div className="row">
                  <div className="application-process-left col-xs-12 col-sm-12 col-md-8 col-lg-8">
                    <img src={phase.img} alt="phase" width="100%" />
                    <h5 className="m-0">{phase.textHeader}</h5>
                    <p style={{ paddingTop: '0.75rem' }}>
                      <b>{phase.textBody}</b>
                    </p>
                  </div>
                  <div className="application-process-right col-xs-12 col-sm-12 col-md-4 col-lg-4 my-auto">
                    <div className="d-none d-md-block">
                      <img
                        src={applicationRightArrow}
                        alt="right arrow"
                        width="100%"
                      />
                    </div>
                    <div className="application-process-down-arrow d-md-none">
                      <img
                        src={applicationRightArrow}
                        alt="right arrow"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="application-button-container">
        <button
          className="application px-0"
          onClick={() => setDropdownActive(false)}
          >
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdfb_g-KthSs4poJfIA0l8tsapl9VA-7CHONiElff8zmFL8fg/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <span>APPLY TODAY!</span> */}
              <span>Available Fall 2024</span>
            </a>
        </button>
      </div>
      
    </div>
  );
}

export default ApplicationProcess;
