import React from 'react';

function Error404() {
  return (
    <div className="section">
      <h1>404 Error</h1>
      <h5>
        Looks like you&apos;ve accidentally stumbled across a bad page url.
        Click on one of the links in the navigation bar above to get you back.
      </h5>
    </div>
  );
}

export default Error404;
