import React from 'react';
import Award from './Award';
import './Home.css';
import Intro from './Intro';
import MaskChallenge from './MaskChallenge';
import OurVideo from './OurVideo';
import Overview from './Overview';
import Schedule from './Schedule';

function Home() {
  return (
    <div className="home-container">
      <Intro />
      <Overview />
      <Schedule />
      {/* <OurVideo /> */}
      <Award />
      <MaskChallenge />
    </div>
  );
}

export default Home;
