import React from 'react';
import './OfficerSquare.css';

function OfficerSquare(props) {
  const {
    modalId,
    color,
    name,
    position,
    img,
    modalImg,
    fbLink,
    linkedinLink,
    email,
    description,
    team,
  } = props;
  return (
    <div
      id={'speaker-' + modalId}
      className="col-xs-12 col-sm-12 col-md-6 col-lg-4"
      style={{ '--theme-color': color }}
    >
      <div
        className="speaker"
        data-toggle="modal"
        data-target={'#speaker-modal-' + modalId}
      >
        <div className="speaker-img">
          <img
            className="card-img-top"
            src={require('' + img)}
            alt={name + ' card header'}
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">{name}</h5>
          <h6 className="card-text">{position}</h6>
          {team && <span className="card-text">{team}</span>}
          <div className="officer-square-social-container">
            {fbLink && (
              <a href={fbLink} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-facebook"></i>
              </a>
            )}
            {linkedinLink && (
              <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
                <i className="fa fa fa-linkedin"></i>
              </a>
            )}
            {email && (
              <a
                href={'mailto:' + email}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa fa-envelope-o"></i>
              </a>
            )}
          </div>
        </div>
      </div>
      <div id={'speaker-modal-' + modalId} className="speaker-modal modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn speaker-modal-close"
              data-dismiss="modal"
            ></button>
            <div className="modal-body p-5">
              <div className="row p-0">
                <div className="col-md-5">
                  <div className="speaker-modal-img">
                    <img
                      src={require('' + modalImg)}
                      alt={name + ' modal header'}
                    />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="speaker-modal-content">
                    <h2 className="speaker-name">{name}</h2>
                    <h4 className="speaker-job">{position}</h4>
                    <p>{description}</p>
                    {(fbLink || linkedinLink || email) && (
                      <div className="speaker-social">
                        <h4>Social Profile</h4>
                        {fbLink && (
                          <a
                            href={fbLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-facebook"></i>
                          </a>
                        )}
                        {linkedinLink && (
                          <a
                            href={linkedinLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa fa-linkedin"></i>
                          </a>
                        )}
                        {email && (
                          <a
                            href={'mailto:' + email}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa fa-envelope-o"></i>
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficerSquare;
