import React from "react";
import './Schedule.css'
import showcaseImg from './final_showcase_2023.png'

function Schedule() {
    const scheduleInfo = {
        title: "Final Showcase 2023",
        events: [
            {
                eventName: 'Festifall (Central)',
                eventTime: '8/28 @6:00 - 8:30 PM',
                location: 'Diag: Section F',
            },
            {
                eventName: 'Mass Meeting',
                eventTime: '9/3 @6 - 7 PM',
                location: 'Angel Hall G040',
            },
            {
                eventName: 'Mass Meeting',
                eventTime: '9/4 @6 - 7 PM',
                link: 'https://umich.zoom.us/j/91893318803',
                linkText: 'Join Here',
            },
            {
                eventName: 'Applications Due',
                eventTime: '9/10 @11:59 PM',
                link: 'https://docs.google.com/forms/d/e/1FAIpQLSdfb_g-KthSs4poJfIA0l8tsapl9VA-7CHONiElff8zmFL8fg/viewform',
                linkText: 'Apply Here',
            },
        ]
    }
    return (
        <div className="schedule-container">
            {/* <div className="schedule-header">
                <text class='showcase-text'>{scheduleInfo.title}</text>
            </div> */}

            <div className="schedule-body">
                <center>
                    <img class="showcase-fit" src={showcaseImg} alt="MedLaunch Final Showcase 2025 Info"/>
                </center>
                {/* {scheduleInfo.events &&
                scheduleInfo.events.map((event, idx) => (
                <div
                    key={idx}
                    className="schedule-event"
                >
                    <div><h5 className="m-0">{event.eventName}</h5></div>
                    <p>{event.eventTime}</p>
                    {event.location && (
                        <p>{event.location}</p>
                    )}
                    {event.link && (
                       <button
                            className="schedule-link"
                            onClick={() => setDropdownActive(false)}
                            >
                                <a
                                href={event.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                    <span>{event.linkText}</span>
                                </a>
                     </button>
                    )}
                </div>
                ))} */}
            </div>

        </div>
    );
}


export default Schedule;